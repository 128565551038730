/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import request from "../utils/api";
import { enquireScreen } from 'enquire-js';
import { LinkedinOutlined } from '@ant-design/icons';

import Forms1 from './Forms1';

import {
  Teams12DataSource,
} from '../utils/company.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      contentData: ''
    };
    this.callback = this.callback.bind(this)
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    this.getVerifyImg()
  }
  showMobileSidebar = () => {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      mobileSidebar: prevState.isToggleOn ? 'none': 'block'
    }));
  }
  onLinkedinClick = () => {
    const link = 'https://www.linkedin.com/company/aerohubtech'
    window.open(link, "_blank");
  }
  getVerifyImg = () => {
    const _t = (new Date()).getTime()
    const params = {
      withCookie: true,
      _t
    }
    request('contact/verify_img', {params}).then(res => {
      this.setState({
        contentData: res.data
      })
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  }
  callback() {
    this.getVerifyImg()
  }
  render() {
    const children = [
      <div className="contact-wrapper" key="Contact_banner">
        <div className="contact-banner">
          <div className="contact-banner-cover"></div>
          <div className="contact-banner-wrapper">
            <div className="contact-banner-text">
              HOW<br />CAN WE HELP
            </div>
          </div>
        </div>
      </div>,
      <div className="contact-wrapper" key="Contact_content">
        <div className="contact-content">
          <div className="title-wrapper">
            <h1 name="title" className="title-h1">LET'S CONNECT</h1>
            <div className="title-bottomline"></div>
          </div>
        </div>
        <div className="contact-content">
          <div className="contact-content-sidebar">
            <div className="siderbar-c-top">
            At AeroHub, we understand that a one-size-fits-all approach doesn't work for air travel sales.<br />
            That's why we are committed to providing a personalized approach for global airlines and online travel agents. Get tailored services to suit your specific needs, and enable travelers to access the best fares, at the best time. 
            </div>
            <div className="siderbar-c-title">
              OUR E-MAIL
            </div>
            <div className="siderbar-c-email">
              <a href="mailto:sales@aerohubtech.com">sales@aerohubtech.com</a>
            </div>
            <div className="siderbar-c-linkicon">
              <LinkedinOutlined twoToneColor="#007BC6" style={{color: '#007BC6', fontSize: '2em'}} onClick={(e) => {
                this.onLinkedinClick(e);
              }} />
            </div>
          </div>
          <div className="contact-content-list">
            <Forms1
              id="Forms1_1"
              key="Forms1_1"
              callback={this.callback}
              datasource={Teams12DataSource}
              imgsource={this.state.contentData}
              ismobile={this.state.isMobile}
            />
          </div>
        </div>
      </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
