/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { Pagination, Spin } from 'antd';
import request from "../utils/api";
import { enquireScreen } from 'enquire-js';

import Feature7 from './Feature7';

import {
  Feature70DataSource,
} from '../utils/news.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      contentData: {
        paginate: {
          current_page: "",
          total: 0
        },
        result: []
      },
      status: null
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    // 获取news列表
    setTimeout(() => {
      this.getNewsList()
    }, 500);
  }
  getNewsList  = (page) => {
    const next = page ? page : 1
    const params = {
      "page": next,
      "limit": 6
    }
    request('news/index', {params}).then(res => {
      const { status, data } = res
      if (status === 0) {
        this.setState({
          contentData: data,
          status
        })
      }
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  }
  onChangeHandle = (page) => {
    this.setState({
      status: -1
    })
    this.getNewsList(page)
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 300;
    }, 0);
  }
  render() {
    const children = [
      <div className="news-wrapper" key="News_banner">
        <div className="news-banner">
          <div className="news-banner-cover"></div>
          <div className="news-banner-wrapper">
            <div className="news-banner-text">
              TO MAKE<br />TRAVEL EFFORTLESS
            </div>
          </div>
        </div>
      </div>,
      <div className="news-wrapper" key="News_content">
        <div className="news-content">
          <div className="news-content-list">,
            {(this.state.status === 0 && this.state.contentData.result.length !== 0) ? (<Feature7
              id="Feature7_0"
              key="Feature7_0"
              dataSource={Feature70DataSource}
              newsSource={this.state.contentData}
              isMobile={this.state.isMobile}
            />) : (<div className="news-loading">
              <Spin size={"large"} />
            </div>)},
            <div className="news-content-dispage">
              <Pagination
                current={parseInt(this.state.contentData.paginate.current_page, 10)}
                defaultCurrent={1}
                defaultPageSize={6}
                onChange={this.onChangeHandle}
                total={this.state.contentData.paginate.total}
              />
            </div>
          </div>
        </div>
      </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
