import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { ContentIcon } from '../utils/airlines.source';
import soon from '../Home/assets/soon.png';

const colConfig = {
  name: 'block0',
  className: 'block',
  xl: 8,
  md: 12,
  xs: 24,
}
const wrapperClass = {
  className: 'content5-block-content'
}
class Content5 extends React.PureComponent {
  getChildrenToRender = (data) => {
    return data.map((item, ii) => {
      const {continent = [], 'service': other } = item
     
      const tagItem = continent.map(($item, i) => {
        return (
          <span key={'tag_' + i.toString()} className="tag">{$item.name}</span>
        );
      });
      const otherItem = other.map(($item, i) => {
        const index = $item.id - 6;
        return (
          <div  key={'icon_' + i.toString()} className="attr-icon" >
            <Tooltip title={$item.name}>
              <div className="attr-icon-wrapper">
                <img src={ContentIcon[index]} className="attr-icon-img" alt="" />
              </div>
            </Tooltip>
          </div>
        );
      });

      let soonBox;
      if (2 != item.status) {
        soonBox = otherItem;
      } else {
        soonBox = <div className="tag-soon"><img src={ soon } /></div>
      }

      return (
        <Col key={ii.toString()} {...colConfig}>
          <div className="content5-block-mg">
            <div {...wrapperClass}>
              <span>
                <img src={item.logo} height="100%" alt="img" />
              </span>
              <div className="airline-name">{item.name}{item.code && '(' + item.code + ')'}</div>
              <div className="tag-wrapper">
                { tagItem }
              </div>
              
              <div className="attr-wrapper">
                { soonBox }
              </div>
            </div>
          </div>
        </Col>
      );
    });
  }

  render() {
    const { ...props } = this.props;
    const { dataSource, airlineSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    delete props.airlineSource;
    const airlineList = airlineSource;
    const childrenToRender = this.getChildrenToRender(airlineList);
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div
            className={`content-template ${props.className}`}
            {...dataSource.OverPack}
          >
            <Row
              {...dataSource.block}
            >
              {childrenToRender}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Content5;
