/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import request from "../utils/api";
import { enquireScreen } from 'enquire-js';

import Content5 from './Content5';
import Side from './Side';

import {
  Content50DataSource,
} from '../utils/airlines.source';
import './less/antMotionStyle.less';
import menuIcon from "./assets/airlines-menu.png";
import closeIcon from "./assets/airlines-close.png";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      sideData: [],
      contentData: [],
      isToggleOn: true,
      mobileSidebar: window.innerWidth < 1024 ? 'none': 'block',
      mobileCover: 'none'
    };
    this.callback = this.callback.bind(this)
  }

  handleResize = e => {
    if( e.target.innerWidth < 1024){
      this.setState(() => ({
        mobileCover: 'none',
        mobileSidebar: 'none'
      }));
    } else {
      this.setState(() => ({
        mobileCover: 'none',
        mobileSidebar: 'block'
      }));
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
      this.setState(() => ({
        mobileCover: 'none',
        mobileSidebar: 'none'
      }));
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    // 获取airline类型
    this.getAirType()
    this.getAirList()
    // resize
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  getAirType  = () => {
    request('airline/filter').then(res => {
      const { status, data } = res
      if (status === 0) {
        const temp = data.map(item => {
          return {
            key: item.title,
            value: item.item
          }
        })
        this.setState({
          sideData: temp
        })
      }
    })
  }
  getAirList  = (ids = []) => {
    const idStr = ids.join(',')
    const params = {
      "ids": idStr
     }
    request('airline/list', {params}).then(res => {
      const { status, data } = res
      if (status === 0) {
        this.setState({
          contentData: data
        })
      }
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  }
  showMobileSidebar = () => {
    this.setState(() => ({
      mobileCover: 'block',
      mobileSidebar: 'block'
    }));
  }
  hideMobileSidebar = () => {
    this.setState(() => ({
      mobileCover: 'none',
      mobileSidebar: 'none'
    }));
  }
  resetMobileSidebar = () => {
    this.sidebarRef.current.resetData()
  }
  callback(childState) {
    this.getAirList(childState)
  }
  render() {
    const children = [
      <div className="airlines-wrapper" key="Airlines_banner">
        <div className="airlines-banner">
          <div className="airlines-banner-cover"></div>
          <div className="airlines-banner-wrapper">
            <div className="airlines-banner-text">
              CONTENT SUPPLIERS
            </div>
          </div>
        </div>
      </div>,
      <div className="airlines-wrapper" key="Airlines_content">
        <div className="airlines-filter">
          <div className="airlines-filter-wrapper" onClick={this.showMobileSidebar}>
            <div className="arilines-icon-img">
              <img src={menuIcon} alt="filter" />
            </div>
            <div className="airlines-icon-text">Filters</div>
          </div>
        </div>
        <div className="airlines-content">
          <div className="airlines-content-sidebar">
            <div className="mobile-sidebar-cover" style={{display: this.state.mobileCover}}></div>
            <div className="mobile-sidebar-wrapper" style={{display: this.state.mobileSidebar}}>
              <div className="mobile-sidebar-header">
                <span className="header-button" onClick={this.resetMobileSidebar}>Reset</span>
                <span className="header-text">Filters</span>
                <img src={closeIcon} className="header-close" alt="close" onClick={this.hideMobileSidebar} />
              </div>
              <div className="mobile-sidebar-content">
                <Side dataSource={this.state.sideData} callback={this.callback} ref={this.sidebarRef} />
              </div>
              <div className="mobile-sidebar-footer">
                <span className="footer-button" onClick={this.hideMobileSidebar}>Show Results</span>
              </div>
            </div>
          </div>
          <div className="airlines-content-list">
            <Content5
              id="Content5_0"
              key="Content5_0"
              dataSource={Content50DataSource}
              airlineSource={this.state.contentData}
              isMobile={this.state.isMobile}
            />
          </div>
        </div>
      </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
