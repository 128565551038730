import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import "echarts/lib/chart/line";
 
class echartLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
          imgType: "line", // 默认折线图
          xtitle: this.props.xtitle, // x轴类目名称取参
        };
    }
    // getOption 这个函数主要用于配置 option，包括将数据配置进去
    // 也可以将其放在 state 中，然后通过 setState 更新
    getOption = () => {
      // 组装数据，返回配置 option
      const currentData = this.props.backData;
      const currentUnit = this.props.backConfig.dataUnit;
      const yAxisName = this.props.backConfig.yAxisName;
      const XAxisDataOrign = currentUnit === 'M' ? currentData.map(item => item.day) : currentData.map(item => item.date)
      const XAxisData = XAxisDataOrign.map(item => {
        const tar = item + "";
        const a = tar.substring(0, 4)
        const b = tar.substring(4, 6)
        const c = tar.substring(6)
        return `${a}-${b}-${c}`
      })
      let fixedData = []
      if (currentUnit === 'M') {
        fixedData = currentData.map(a => (a.num/1000000).toFixed(2))
      } else if (currentUnit === 's') {
        fixedData = currentData.map(a => a.num.toFixed(2))
      } else {
        fixedData = currentData.map(a => a.num.toFixed(2))
      }
      const gridInfo = Object.assign({}, {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      }, this.props.backConfig.grid) 
      const clearData = {
        type: 'line',
        itemStyle : {
          normal: {
            label : {
              show: true,
              formatter: function (params) {
                let value = params.value + currentUnit
                return value
              },
          }
          }
        },
        // data: currentData.map(a => a.cleanAmount) || []
        data: fixedData
      };
      return {
        color: ["#386db3", "#e5323e"], // 图颜色
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var htmlStr = '<div>';
            htmlStr += params.name + '<br/>';//x轴的名称
            htmlStr += '<span ></span>';
            htmlStr += 'value: ' + params.value + currentUnit;
            htmlStr += '</div>';
            return htmlStr;
          }
        },
        animationDuration: 1000,
        grid: gridInfo,
        xAxis: {
            type: "category",
            data: XAxisData
        },
        yAxis: {
            type: "value",
            name: yAxisName,
            nameLocation: 'middle',
            nameTextStyle: {
              lineHeight: currentUnit === 'M' ? 100 : 60
            },
            position: 'left',
            axisLine: {
              show: true
            },
            axisLabel: {
              formatter: '{value}'+ currentUnit
            },
        },
        series: [clearData]
    }
  };
    render() {
      return(
        <ReactEcharts
          style={{ minHeight: "400px" }}
          option={this.getOption()}
          notMerge
          lazyUpdate
          theme={"theme_name"}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
        />
      )
    }
}
 
export default echartLine;