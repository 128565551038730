import React from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';

import { getChildrenToRender } from '../utils/index';

function Brand1(props) {
  const { dataSource, isMobile, ...tagProps } = props;
  const { blockWrapper, titleWrapper } = dataSource;
  const childrenToRender = blockWrapper.children.map((item, i) => {
    // eslint-disable-next-line
    const { children: colChild, arrow, ...colProps } = item;
    const colItem = colChild.children.map((itm, inx) => {
      return (
        <div {...itm} key={inx.toString()}>
          <img src={itm.children} alt="img" />
        </div>
      );
    });

    return (
      <QueueAnim
        key={'queue_' + i}
        type="bottom"
        leaveReverse
        interval={50}
        component={Row}
        {...blockWrapper}
      >
        {colItem}
      </QueueAnim>
    );
  });
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>{titleWrapper.children.map(getChildrenToRender)}</div>
        <div>{childrenToRender}</div>
      </div>
    </div>
  );
}

export default Brand1;
