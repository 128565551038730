
import React from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from 'react';

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children = ((typeof item.children === 'string' && item.children.match(isImg)) ||
  item.name.indexOf('image') === 0)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children;
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};

export const getObjectType = (obj) => {
  const type = Object.prototype.toString.call(obj)
  return type
}
export const parsePlainObjectToArray = (obj, keyStr) => {
  // 有keyStr返回相关, 无keyStr返回(key, value)模式,并全部放入数组
  let keyTemp = []
  for (let key in obj) {
    if (keyStr) {
      if (keyStr === key) {
        keyTemp = obj[key]
      } else if (keyStr === 'OtherItem' && key !== 'Continent'){
        keyTemp = keyTemp.concat(obj[key])
      }
    }
  }
  return keyTemp
}

export const withRouter = function(Component) {
  function componentWithRouterProps(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      const updateSize = () => setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  
    return (
      <Component
        {...props}
        router={{location, navigate, params, windowSize}}
      />
    )
  }
  return componentWithRouterProps
}