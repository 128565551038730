import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils/index';
import Echart from "./Echart.jsx";

class Banner6 extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    // eslint-disable-next-line
    const { dataSource, backSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.backSource;
    delete tagProps.backConfig
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
            <div className="chartBox">
              <Echart
                backData={backSource}
                backConfig={dataSource.config}
                xtitle="date"
              />
            </div>

          </QueueAnim>
        </div>
      </div>
    );
  }
}
export default Banner6;
