/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { Spin } from 'antd';
import request from "../utils/api";
import { enquireScreen } from 'enquire-js';
import { withRouter } from '../utils/index';

import Detail4 from './Detail4';
import Detail5 from './Detail5';

import {
  Detail40DataSource,
  Detail50DataSource,
} from '../utils/news.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

class Detail extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line
    const { 'router': { params, ...other }} = this.props
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      lastestData: [],
      detailData: {
        previous: {},
        next: {},
        result: {}
      },
      status: null,
      id: params.id
    };
  }
  componentDidUpdate() {
     // eslint-disable-next-line
    const { 'router': { params, ...other }} = this.props
    if (params.id !== this.state.id) {
       // eslint-disable-next-line
      this.state.id = params.id
      // 获取news last列表
      this.getNewsLast()
      // 获取新闻详情
      this.setState({
        status: -1
      })
      // 滚动到合适的位置
      document.body.scrollTop = document.documentElement.scrollTop = 100;
      setTimeout(() => {
        this.getNewsDetail()
      }, 0);
    }
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    // 获取news last列表
    this.getNewsLast()
    // 滚动到顶部
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // 获取新闻详情
    this.getNewsDetail()
  }
  getNewsLast  = () => {
    const data = {
      "id": this.state.id,
      limit: 3
    }
    request('news/latest', {'params': data}).then(res => {
      const { status, data } = res
      if (status === 0) {
        this.setState({
          lastestData: data
        })
      }
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  }
  getNewsDetail  = () => {
    const id = this.state.id;
    request(`news/detail/` + id).then(res => {
      const { status, data } = res
      if (status === 0) {
        this.setState({
          detailData: data,
          status
        })
      }
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  }
  render() {
    const children = [
      <div className="news-wrapper" key="News_detail">
        <div className="news-content">
          <div className="news-content-list">
            {(this.state.status === 0 && this.state.detailData.result.id) ? (<Detail4
              id="Detail4_0"
              key="Detail4_0"
              dataSource={Detail40DataSource}
              newsSource={this.state.detailData}
              isMobile={this.state.isMobile}
            />) : (<div className="news-loading">
              <Spin size={"large"} />
            </div>)}
          </div>
        </div>
      </div>,
      <div className="news-wrapper news-wrapper-lastpost" key="News_lastpost">
        <div className="news-content">
          <div className="news-content-recommend">
            {(this.state.status === 0 && this.state.detailData.result.id) ? (<Detail5
              id="Detail5_0"
              key="Detail5_0"
              dataSource={Detail50DataSource}
              newsSource={this.state.lastestData}
              isMobile={this.state.isMobile}
            />) : (<div className="news-loading">
              <Spin size={"large"} />
            </div>)}
          </div>
        </div>
      </div>
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
export default withRouter(Detail)
