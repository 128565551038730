import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Checkbox, Divider, Row, Col } from 'antd';
import { SideIcon } from '../utils/airlines.source';
const CheckboxGroup = Checkbox.Group;
let plainOptions = [];
const defaultCheckedList = [];

const Side = forwardRef(({...props}, ref) => {
  useImperativeHandle(ref, () => ({
    resetData
  }))

  const { dataSource, callback } = props;
  plainOptions = dataSource.reduce((acc, cur, i) => {
    const oArr = cur.value || []
    const lArr = oArr.map(itm => itm.id)
    return acc.concat(lArr)
  }, [])
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  /* eslint-disable */
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  /* eslint-enable */
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    callback(list)
  };
  // eslint-disable-next-line
  const onCheckAllChange = (e) => {
    const data = e.target.checked ? plainOptions : []
    setCheckedList(data);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    callback(data)
  };
  const resetData = () => {
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
    callback([])
  }

  const sideRender = dataSource.map((item, i) => {
    const {key, value} = item;
    const checkboxItem = value.map((itm, inx) => {
      const index = itm.id - 6
      return (
        <div className="sidebar-flex" key={`item_${inx}`}>
          <div className="sidebar-icon-wrapper" style={{display: key === 'Continent' ? 'none' : 'block'}}>
            <img src={SideIcon[index]} alt="" key={`icon_${i}_${inx}`} />
          </div>
          <Checkbox value={itm.id} key={`checkbox_${i}_${inx}`}>{itm.name}</Checkbox>
        </div>
      )
    })
    return (
      <div key={key}>
        {i !== 0  && <Divider />}
        <div className="sidebar-type">{item.key}</div>
        <Row>
          <Col span="24">
              {checkboxItem}
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div className="side-wrapper">
      {/* <div className="sidebar-type">Filter</div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        All
      </Checkbox> */}
      <CheckboxGroup value={checkedList} onChange={onChange}>
        {sideRender}
      </CheckboxGroup>
    </div>
  );
});
export default Side;