export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper newsf7-wrapper' },
  page: { className: 'home-page newsf7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'newsf7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'newsf7-title-h1',
        children: 'NEWS',
      },
    ],
  },
  blockWrapper: {
    className: 'newsf7-block-wrapper',
    gutter: 24
  },
};
export const Detail40DataSource = {
  wrapper: { className: 'home-page-wrapper newsdd4-wrapper' },
  page: { className: 'home-page newsdd4' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'newsdd4-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'newsdd4-title-h1',
        children: 'Driving Efficiency with Low-code/No-code Platforms in travel industry',
      },
      {
        name: 'titleDate',
        className: 'newsdd4-title-date',
        children: 'February 23, 2022',
      },
    ],
  },
  blockWrapper: {
    className: 'newsdd4-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 24,
        xs: 24,
        name: 'block',
        className: 'newsdd4-block',
        children: {
          className: 'newsdd4-block-group',
          children: [
            {
              name: 'image',
              className: 'newsdd4-block-image'
            },
            {
              name: 'title',
              className: 'newsdd4-block-title',
              children: 'Driving Efficiency with Low-code/No-code Platforms in travel industry',
            },
            {
              name: 'content',
              className: 'newsdd4-block-content',
              children:
                'Mystifly introduces Multi-City Search - an advanced flight search feature that makes searching and booking consecutive one-way flights between multiple',
            },
            {
              name: 'date',
              className: 'newsdd4-block-date',
              children: 'February 23, 2022',
            },
          ],
        },
      }
    ],
  },
};
export const Detail50DataSource = {
  wrapper: { className: 'home-page-wrapper newsdd5-wrapper' },
  page: { className: 'home-page newsdd5' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'newsdd5-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'newsdd5-title-h1',
        children: 'LATEST POSTS',
      },
    ],
  },
  blockWrapper: {
    className: 'newsdd5-block-wrapper',
    gutter: 24,
    children: [
      {
        lg: 12,
        md: 24,
        xs: 24,
        name: 'block',
        className: 'newsdd5-block'
      },
      {
        lg: 12,
        md: 24,
        xs: 24,
        name: 'block1',
        className: 'newsdd5-block'
      },
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'newsdd5-block'
      }
    ],
  },
};