import React from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';

import { getChildrenToRender } from '../utils/index';
import { Link } from "react-router-dom";

const colConfig = {
  lg: 12,
  md: 24,
  xs: 24,
  name: 'block',
  className: 'newsf7-block'
}

function Feature7(props) {
  const { dataSource, newsSource, isMobile, ...tagProps } = props;
  const { blockWrapper, titleWrapper } = dataSource;
  const { result = [] } = newsSource
  const childrenToRender = result.map((item, i) => {
    let tempObj = [
      {
        name: 'image',
        className: 'newsf7-block-image',
        children: item.thumbnail_img
      },
      {
        name: 'title',
        className: 'newsf7-block-title',
        children: item.title,
      }
    ]
    return (<Col {...colConfig} key={i.toString()}>
      <Link className="newsf7-block-group" to={{
        pathname: "/newsDetail/" + item.id
      }}>
        <div className="block-text">
            {tempObj.map(getChildrenToRender)}
            <div name="content" className="newsf7-block-content" dangerouslySetInnerHTML={{__html: item.intro}}></div>
        </div>
        <div className="block-addon">
            <span className="newsf7-block-date"> {item.created_at} </span>
            <span className="newsf7-block-readmore">Read More</span>
        </div>
      </Link>
    </Col>)
  });
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
          <div className="newsf7-title-bottomline"></div>
        </div>
        <div>
          <Row
            {...blockWrapper}
          >
            {childrenToRender}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Feature7;
