import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';

import { getChildrenToRender } from '../utils/index';

function Feature5(props) {
  const { dataSource, isMobile, ...tagProps } = props;
  const { blockWrapper } = dataSource;
  const childrenToRender = blockWrapper.children.map((item, i) => (
    <Col {...item} key={i.toString()}>
      <div {...item.children}>{item.children.children.map(getChildrenToRender)}</div>
    </Col>
  ));
  const animType = {
    queue: 'bottom',
    one: {
      y: '+=30',
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.childWrapper}
          componentProps={{
            md: dataSource.childWrapper.md,
            xs: dataSource.childWrapper.xs,
          }}
        >
          {dataSource.childWrapper.children.map(getChildrenToRender)}
        </QueueAnim>

        <div>
          <Row
            {...dataSource.blockWrapper}
          >
            {childrenToRender}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Feature5;
