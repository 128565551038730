import React from 'react';
import { Row, Col, Form, Input, message, Image } from 'antd';
import { getChildrenToRender } from '../utils/index';
import request from "../utils/api";

function Forms1(props) {
  const { datasource, imgsource, callback } = props;
  const { block, childWrapper} = datasource;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const handleSubmit = (e) => {
    e.preventDefault()
    form.submit()
  }
  const onFinish = (values) => {
    // console.log('Success:', values);
    const options = {
      'method': 'POST',
      'body': values,
      'params': {
        withCookie: true
      }
    }
    request('contact/add', options).then(res => {
      const { status, msg } = res
      if (status === 0) {
        messageApi.open({
          type: 'success',
          content: 'submit success!',
        });
      } else {
        messageApi.open({
          type: 'error',
          content: msg,
        });
      }
    }).catch(e =>{
      console.log("error:" + JSON.stringify(e))
    })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onRefresh = () => { 
    const _t = (new Date()).getTime()
    callback(_t)
  };
  const listChildren = block.children.map((item, i) => {
    const { inputWrapper, ...$item } = item;
    return (
      <Col key={i.toString()} {...$item}>
        <Form.Item 
          label={$item.children} 
          name={$item.name} 
          required={$item.itemType === 'inputItem' || $item.itemType === 'textAreaItem'}
          rules={$item.rules}
        >
          {$item.itemType === 'inputItem' ? (<Input placeholder="" />) : (<Input.TextArea rows={4} placeholder="" />)}
        </Form.Item>
      </Col>
    );
  });
  return (
    <div {...props} {...datasource.wrapper}>
      <div {...datasource.page}>
        <Row className='block-wrapper' gutter='24'>
          <Form form={form} layout="vertical" name="userForm" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {listChildren}
            {/* <Col span="12">
              <div className="captcha-wrapper" onClick={onRefresh}>
                <Image
                  src={imgsource}
                  preview={false}
                />
              </div>
            </Col>
            {contextHolder} */}
            <div key="button" {...childWrapper} onClick={handleSubmit}>
              {childWrapper.children.map(getChildrenToRender)}
            </div>
          </Form>
        </Row>
      </div>
    </div>
  );
}

export default Forms1;
